import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { camelCase } from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { slugify } from "utils/index";

const OTHER_COUNTRY = {
  fr_fr: "Autres Pays",
  en_gb: "Other countries",
  es_es: "Otros países",
  de_de: "Andere Länder",
};

const Countries = ({
  companyTitle,
  companyPronoun,
  countries,
  staticTexts,
  lang,
  mobile,
}) => {
  const [_countries, _setCountries] = useState([]);
  const [imageReRender, setRerender] = useState(false);

  useEffect(() => {
    if (_countries.length !== countries.length) {
      setRerender(true);
      _setCountries([...countries]);

      setTimeout(() => {
        setRerender(false);
      }, 1000);
    }
    //eslint-disable-next-line
  }, [countries]);

  if (companyTitle) {
    return (
      <section className={"container page-width top-margin" + (mobile ? " mobile" : "")}>
        <div className="countries-card card-primary">
          <div className="countries-header">
            {mobile ? (
              <h2>
                {staticTexts.componentCountriesH2Mobile.replace(
                  "{{ company_name }}",
                  `${companyPronoun ? companyPronoun + " " : ""}${companyTitle}`
                )}
              </h2>
            ) : (
              <div className="h2-title">{staticTexts.componentCountriesH2}</div>
            )}
          </div>

          <div className="countries-container-list">
            {_countries
              .filter(
                (country) =>
                  camelCase(country.menuTitle) !==
                  camelCase(OTHER_COUNTRY[lang])
              )
              .map((country, index) => (
                <div className="countries-block-list" key={index}>
                  <Link
                    to={`/${slugify(
                      `${staticTexts.componentCountriesSlug}${
                        country.pronoun ? country.pronoun + "-" : ""
                      }${country.slug}`
                    )}`}
                  >
                    <LazyLoadImage
                      src={country.image.fields.file.url}
                      width="40px"
                      height="40px"
                      alt={`${staticTexts.componentCountriesAltTextPart1} ${
                        companyPronoun ? companyPronoun + " " : ""
                      }${companyTitle} ${
                        staticTexts.componentCountriesAltTextPart2
                      } ${country.pronoun ? country.pronoun : ""}${
                        country.menuTitle
                      }`}
                    />
                    {mobile ? (
                      <h3>{country.menuTitle}</h3>
                    ) : (
                      <h3>
                        {staticTexts.componentCountriesH3Part1}{" "}
                        {companyPronoun ? companyPronoun + " " : ""}
                        {companyTitle} {staticTexts.componentCountriesH3Part2}{" "}
                        {country.pronoun ? country.pronoun : ""}
                        {country.menuTitle}
                      </h3>
                    )}
                  </Link>
                </div>
              ))}
            {_countries
              .filter(
                (country) =>
                  camelCase(country.menuTitle) ===
                  camelCase(OTHER_COUNTRY[lang])
              )
              .map((country, index) => (
                <div className="countries-block-list" key={index}>
                  <Link
                    to={`/${slugify(
                      `${staticTexts.componentCountriesSlug}${
                        country.pronoun ? country.pronoun + "-" : ""
                      }${country.slug}`
                    )}`}
                  >
                    <LazyLoadImage
                      src={country.image.fields.file.url}
                      width="40px"
                      height="40px"
                      alt={`${staticTexts.componentCountriesAltTextPart1} ${
                        companyPronoun ? companyPronoun + " " : ""
                      }${companyTitle} ${
                        staticTexts.componentCountriesAltTextPart2
                      } ${country.pronoun ? country.pronoun : ""}${
                        country.menuTitle
                      }`}
                    />
                    {mobile ? (
                      <h3>{country.menuTitle}</h3>
                    ) : (
                      <h3>
                        {staticTexts.componentCountriesH3Part1}{" "}
                        {companyPronoun ? companyPronoun + " " : ""}
                        {companyTitle} {staticTexts.componentCountriesH3Part2}{" "}
                        {country.pronoun ? country.pronoun : ""}
                        {country.menuTitle}
                      </h3>
                    )}
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="container page-width">
      <div className="countries-card card-primary">
        <div className="countries-header">
          <div className="h2-title">{staticTexts.componentCountriesH2}</div>
        </div>

        <div className="countries-container">
          <div className="countries-row">
            {_countries
              .filter(
                (country) =>
                  camelCase(country.menuTitle) !==
                  camelCase(OTHER_COUNTRY[lang])
              )
              .map((country, index) => (
                <div className="countries-block" key={index}>
                  <Link
                    to={`/${slugify(
                      `${staticTexts.componentCountriesSlug}${
                        country.pronoun ? country.pronoun + "-" : ""
                      }${country.slug}`
                    )}`}
                  >
                    {!imageReRender && country.image ? (
                      <LazyLoadImage
                        width="100px"
                        height="100px"
                        src={country.image.fields.file.url}
                        alt={country.altText ?? country.pageTitle}
                        key={index}
                      />
                    ) : null}
                    <h3>{country.menuTitle}</h3>
                  </Link>
                </div>
              ))}
            {_countries
              .filter(
                (country) =>
                  camelCase(country.menuTitle) ===
                  camelCase(OTHER_COUNTRY[lang])
              )
              .map((country) => (
                <div className="countries-block" key={country.menuTitle}>
                  <Link
                    to={`/${slugify(
                      `${staticTexts.componentCountriesSlug}${
                        country.pronoun ? country.pronoun + "-" : ""
                      }${country.slug}`
                    )}`}
                  >
                    {!imageReRender && country.image ? (
                      <LazyLoadImage
                        width="100px"
                        height="100px"
                        src={country.image.fields.file.url}
                        alt={country.altText ?? country.pageTitle}
                        key={"Other Country"}
                      />
                    ) : null}
                    <h3>{country.menuTitle}</h3>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Countries;
