import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import Countries from "components/Countries";
import Partenaires from "components/Partenaires";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LandingPage = ({ pageContext: content, location }) => {
  const {
    _homeContent,
    _companies,
    _countries,
    _others,
    _otherCountries,
    _partners,
    _staticTexts,
    _topMenus,
    _companyMenus,
    _lang,
 _blogs,
  } = content;

  const [slide, setSlide] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      if (slide < 6) {
        setSlide(slide + 1);
      } else {
        setSlide(1);
      }
    }, 3000);
  }, [slide]);

  return (
    <Layout
      menuContent={{
        companies: _companies,
        countries: _countries,
        others: _others,
        otherCountries: _otherCountries,
        topMenus: _topMenus,
        companyMenus: _companyMenus,
        blogs: _blogs,
      }}
      menuSection={"Home"}
      menuTitle={"Losali"}
      slug={_homeContent.slug}
      ratingValue={_homeContent.ratingValue || 4.9}
      ratingCount={_homeContent.ratingCount || 121}
      staticTexts={_staticTexts}
      lang={_lang}
    >
      <Helmet>
        <title>{_homeContent.pageTitle}</title>
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={_homeContent.metaKeywords} />
        <meta name="description" content={_homeContent.metaDescription} />
        <link rel="canonical" href={_staticTexts.websiteUrl} />
      </Helmet>
      <main className="container page-width top-margin">
        <div className="main-card card-primary">
          <span className="main-card-left">
            <h1 className="main-card-h1">{_homeContent.h1}</h1>
            <h4 className="main-description">{_homeContent.body}</h4>
            <div className="main-confirmation">
              <StaticImage
                src="../assets/icons/confirmation.svg"
                placeholder="blurred"
                layout="fixed"
                alt="..."
              />

              <h4>{_homeContent.confirmation1}</h4>
            </div>

            <div className="main-confirmation">
              <StaticImage
                src="../assets/icons/confirmation.svg"
                placeholder="blurred"
                layout="fixed"
                alt="..."
              />

              <h4>{_homeContent.confirmation2}</h4>
            </div>
          </span>
          <span className="main-card-right">
            <StaticImage
              src="../assets/images/main-card-img.webp"
              placeholder="blurred"
              layout="constrained"
              alt="..."
            />
          </span>
        </div>
      </main>

      <Countries
        countries={[..._countries, ..._otherCountries].sort((a, b) =>
          a.menuTitle.localeCompare(b.menuTitle)
        )}
        staticTexts={_staticTexts}
        lang={_lang}
      />

      <section className="container page-width">
        <div className="pourquoi-card card-secondary">
          <div className="pourquoi-card-left">
            <div className="h2-title">{_homeContent.h2}</div>
            <h5>{_homeContent.about}</h5>
          </div>
          <div className="pourquoi-card-right">
            {[...Array(6).keys()].map((num) => (
              <div
                style={{
                  height: slide === num + 1 ? "200px" : 0,
                  width: slide === num + 1 ? "297px" : 0,
                  overflow: "hidden",
                }}
                key={num}
              >
                {slide === num + 1 ? (
                  <LazyLoadImage
                    src={_staticTexts.cartoon[num].file}
                    alt={_staticTexts.cartoon[num].title}
                    width="297px"
                    height="200px"
                  />
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </section>

      <Partenaires partners={_partners} />
    </Layout>
  );
};

export default LandingPage;
